import routerPath from "@/router/routerPath";

// 구매기안관리
export function getPurchaseDraftDetailPath(pdDocStatus, pdAprvlStatus) {
  if (!pdDocStatus && !pdAprvlStatus) return "#none";
  const pdStatus = pdDocStatus + pdAprvlStatus;

  // 공개문서
  if (pdAprvlStatus == "O") {
    return routerPath.PURCHASE_REQUEST_VIEW_DETAIL;
  }

  switch (pdStatus) {
    // 내 문서함
    case "T":
      return routerPath.PURCHASE_REQUEST_MODIFY; // 임시저장
    case "I":
      return routerPath.PAYMENT_DRAFT_ING_DETAIL; // 진행중
    case "C":
      return routerPath.PURCHASE_REQUEST_DRAFT_COMPLETE_DETAIL; // 완료
    case "J":
      return routerPath.PURCHASE_REQUEST_DRAFT_REJECT_DETAIL; // 반려
    // 내 결재함
    case "W":
      return routerPath.PURCHASE_REQUEST_APPROVAL_BEFORE_DETAIL; // 결재전
    case "R":
      return routerPath.PURCHASE_REQUEST_APPROVAL_RECEIVE_DETAIL; // 수신된
    case "A":
      return routerPath.PURCHASE_REQUEST_APPROVAL_ING_DETAIL; // 진행중
    case "CALL":
      return routerPath.PURCHASE_REQUEST_APPROVAL_COMPLETE_DETAIL; // 완료
    case "JALL":
      return routerPath.PURCHASE_REQUEST_APPROVAL_REJECT_DETAIL; // 반려
    // 참조 문서함
    case "IN":
      return routerPath.PURCHASE_REQUEST_CC_ING_DETAIL; // 진행중
    case "CN":
      return routerPath.PURCHASE_REQUEST_CC_COMPLETE_DETAIL; // 처리
  }
}

// 정산서 관리
export function getStatementDetailPath(dsDocStatus, dsAprvlStatus) {
  if (!dsDocStatus && !dsAprvlStatus) return "#none";
  const dsStatus = dsDocStatus + dsAprvlStatus;

  // 공개문서
  if (dsAprvlStatus == "O") {
    return routerPath.STATEMENT_DETAIL_VIEW;
  }

  switch (dsStatus) {
    // 내 문서함
    case "T":
      return routerPath.STATEMENT_MODIFY; // 임시저장
    case "I":
      return routerPath.STATEMENT_DRAFT_ING_DETAIL; // 진행중
    case "C":
      return routerPath.STATEMENT_DRAFT_COMPLETE_DETAIL; // 완료
    case "J":
      return routerPath.STATEMENT_DRAFT_REJECT_DETAIL; // 반려
    // 내 결재함
    case "W":
      return routerPath.STATEMENT_APPROVAL_BEFORE_DETAIL; // 결재전
    case "R":
      return routerPath.STATEMENT_APPROVAL_RECEIVE_DETAIL; // 수신된
    case "A":
      return routerPath.STATEMENT_APPROVAL_ING_DETAIL; // 진행중
    case "CALL":
      return routerPath.STATEMENT_APPROVAL_COMPLETE_DETAIL; // 완료
    case "JALL":
      return routerPath.STATEMENT_APPROVAL_REJECT_DETAIL; // 반려
    // 참조 문서함
    case "IN":
      return routerPath.STATEMENT_CC_ING_DETAIL; // 진행중
    case "CN":
      return routerPath.STATEMENT_CC_COMPLETE_DETAIL; // 처리
  }
}

export function windowOpenToApproval(path) {
  return path;
  // else {

  // }
}
