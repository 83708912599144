<template>
  <Popup popupType="tableType" width="940px">
    <div class="section_comm">
      <div class="head_section">
        <h3 class="tit_section">신청서 상세 정보</h3>
      </div>
      <div class="body_section">
        <TableView>
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 266px" />
            <col style="width: 192px" />
            <col style="width: 266px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>신청서명</th>
              <td colspan="3" v-html="itemData.title || ''" />
            </tr>
            <tr>
              <th>신청서 번호</th>
              <td>{{ itemData.requestCid }}</td>
              <th>승인 일자</th>
              <td>{{ requestDate }}</td>
            </tr>
            <tr>
              <th>신청자</th>
              <td>{{ itemData.requester || "" }}</td>
              <th>신청 부서</th>
              <td class="td_text">
                {{ itemData.requestDept || "" }}
              </td>
            </tr>
            <tr>
              <th>승인자</th>
              <td colspan="3">
                {{ itemData.signer || "" }}
              </td>
            </tr>
            <tr>
              <th>분류(대/중/소)</th>
              <td colspan="3">
                {{ category }}
              </td>
            </tr>
            <tr>
              <th>자산 구분</th>
              <td class="td_text">
                {{ itemData.assetType || "" }}
              </td>
              <th>사용자</th>
              <td>{{ itemData.requestUser || "" }}</td>
            </tr>
            <tr>
              <th>신청 품목명</th>
              <td class="td_text">
                {{ itemData.goods || "" }}
              </td>
              <th>신청 수량</th>
              <td>{{ itemData.amount || "" }}</td>
            </tr>
            <tr>
              <th>신청 사유</th>
              <td colspan="3" class="td_text">
                {{ itemData.reason || "" }}
              </td>
            </tr>
            <tr>
              <th>사용자 근무지</th>
              <td colspan="3" class="td_text">
                {{ itemData.place || "" }}
              </td>
            </tr>
            <tr>
              <th>비고</th>
              <td colspan="3" class="h96 td_text" v-html="itemData.note || ''" />
            </tr>
          </template>
        </TableView>
      </div>
    </div>
    <div class="group_btn">
      <button type="button" class="btn_fourthly btn_medium" @click="onClickClose">닫기</button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import TableView from "@/components/shared/tableView/TableView";

import ApiService from "@/services/ApiService";

import { getDateString } from "@/utils/dateUtils";
import { getCategoryDepthText } from "@/utils/spUtils";

export default {
  name: "PurchaseRequestDetailPopup",
  components: {
    Popup,
    TableView,
  },
  props: {
    requestCid: String,
  },
  data() {
    return {
      itemData: {},
    };
  },
  computed: {
    requestDate() {
      const requestDate = this.itemData.requestDate;
      //if( !requestCid ) return '';
      if (!requestDate) return "";

      // e01_20191201_0004 (2019.12.01)
      return `${getDateString(requestDate)}`;
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.itemData || !this.itemData.category) return "";
      return getCategoryDepthText(this.itemData.category);
    },
  },
  beforeMount() {
    const path = `${this.$apiPath.PURCHASE}/${this.requestCid}`;

    this.getData(path);
  },
  methods: {
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.$emit("alert", result.text);
        return;
      }

      this.itemData = result.data;
    },
    onClickClose() {
      this.$emit("onClickClose");
    },
  },
};
</script>
