<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">공고 관리</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="입찰공고 정보">
          <template v-slot:body>
            <tr>
              <th>입찰 공고번호</th>
              <td>{{ itemData.postCid }}</td>
              <th>입찰 시작일</th>
              <td>{{ startDate }}</td>
            </tr>
            <tr>
              <th>입찰 공고명</th>
              <td v-html="itemData.title" />
              <th>입찰 마감일</th>
              <td>{{ endDate }}</td>
            </tr>
            <tr>
              <th>분류</th>
              <td colspan="3">
                {{ category }}
              </td>
            </tr>
            <tr>
              <th>구매요청서 연결</th>
              <td colspan="3">
                <ConnectView
                  :dataList="itemData.purchaseWfPostList"
                  :disabledData="{ aprvlStatus: '-' }"
                  @onClickConnect="onClickConnectPurchaseWfPost"
                />
              </td>
            </tr>
            <!-- <tr v-if="isShowPurchaseRequest && itemData.requestPostList && itemData.requestPostList.length > 0">
              <th>구매신청서 연결</th>
              <td colspan="3">
                <ConnectView
                  :dataList="itemData.requestPostList"
                  @onClickConnect="onClickConnectRequestPost" />
              </td>
            </tr> -->
          </template>
        </TableViewWithTitle>
        <!-- <PurchaseJiraConnectView
          v-if="isShowPurchaseJira && itemData.requestPostList && itemData.requestPostList.length > 0"
          :connectDataList="itemData.requestPostList"
          @onClickDetailJira="onClickDetailJira" /> -->
        <!-- <TableViewWithTitle
          v-if="isShowPurchaseRequest && itemData.requestPostList && itemData.requestPostList.length > 0"
          title="구매신청서 연결">
          <template v-slot:colgroup>
            <col style="width:192px;">
            <col>
          </template>
          <template v-slot:body>
              <th>구매신청서 연결</th>
              <td>
                <ConnectView
                  :dataList="itemData.requestPostList"
                  @onClickConnect="onClickConnectRequestPost" />
              </td>
            </tr>
          </template>
        </TableViewWithTitle> -->
        <TableViewWithTitle title="담당자 정보">
          <template v-slot:header>
            <tr>
              <th colspan="2">구매 담당</th>
              <th colspan="2">현업 담당</th>
            </tr>
          </template>
          <template v-slot:body>
            <tr>
              <th>구매 담당자</th>
              <td>{{ managerPurchase | idWithNameByObj }}</td>
              <th>현업 담당자</th>
              <td>{{ managerFieldWork ? managerFieldWork.name : "" }}</td>
            </tr>
            <tr>
              <th>전화번호 (내선)</th>
              <td>{{ managerPurchase ? managerPurchase.tel : "" }}</td>
              <th>전화번호 (내선)</th>
              <td>{{ managerFieldWork ? managerFieldWork.tel : "" }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                {{ managerPurchase ? managerPurchase.email : "" }}
              </td>
              <th>이메일</th>
              <td>
                {{ managerFieldWork ? managerFieldWork.email : "" }}
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle title="입찰상품 정보">
          <template v-slot:body>
            <tr>
              <th>구매품목</th>
              <td>{{ itemData.itemName }}</td>
              <th>입찰수량</th>
              <td>{{ quantity }}</td>
            </tr>
            <tr>
              <th>예산</th>
              <td colspan="3">
                {{ budget }}
              </td>
            </tr>
            <tr>
              <th>RFP</th>
              <td colspan="3">
                <FileView :dataList="itemData.attachFileList" />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle v-if="noteDecoded" title="입찰상품 추가 정보">
          <template v-slot:body>
            <tr>
              <td colspan="4" class="td_full td_text" v-html="noteDecoded" />
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle title="평가방법 설정">
          <template v-slot:body>
            <tr>
              <th>업체 산정 방식</th>
              <td>
                <div v-if="isChoiceTypeOnlyPrice" class="group_form">
                  <span class="txt_view w80">가격</span>
                  <span class="txt_view">100</span>
                  <span class="txt_view_r">%</span>
                </div>
                <div v-else class="group_form">
                  <span class="txt_view w80">가격&#47;기술</span>
                  <span class="txt_view">{{
                    itemData.postEvaluate ? itemData.postEvaluate.pricePercent : ""
                  }}</span>
                  <span class="txt_view_r">%</span>
                  <span class="txt_view_c w40">&#47;</span>
                  <span class="txt_view">{{
                    itemData.postEvaluate ? itemData.postEvaluate.skillPercent : ""
                  }}</span>
                  <span class="txt_view_r">%</span>
                </div>
              </td>
              <th>가격 공개 방식</th>
              <td>{{ priceTypeText }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
        <div class="area_view">
          <TableHead title="입찰 요청업체 정보" />
          <Board
            :disableHover="true"
            :dataList="itemData.postPersonList || []"
            :showPagination="false"
          >
            <template v-slot:tr>
              <th>대상 업체</th>
              <th>파트너사 담당자</th>
              <th>담당 업무</th>
              <th>이메일</th>
              <th>휴대전화</th>
            </template>
            <template v-slot:rows>
              <template v-for="item in itemData.postPersonList || []">
                <tr :key="item.id">
                  <td class="td_ellip" :data-ellip="item.companyName">
                    {{ item.companyName }}
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.job }}</td>
                  <td class="td_ellip align_left" :data-ellip="item.email">
                    {{ item.email }}
                  </td>
                  <td>{{ item.mobile }}</td>
                </tr>
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="5" class="td_empty">선택한 업체가 없습니다.</td>
              </tr>
            </template>
          </Board>
        </div>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>

        <button
          v-if="itemData.status === 'T'"
          type="button"
          class="btn_fifthly btn_large"
          @click="onClickPopDelete"
        >
          삭제
        </button>
      </div>
      <div class="area_right">
        <!-- 마감 버튼 임시로 넣습니다. 현재 임시 저장 상태가 없음.-->
        <button
          v-if="itemData.status === 'S'"
          type="button"
          class="btn_primary btn_large"
          @click="onClickEnd"
        >
          마감
        </button>
        <button
          v-if="itemData.status === 'T'"
          type="button"
          class="btn_fourthly btn_large"
          @click="onClickModify"
        >
          수정
        </button>

        <button
          v-if="itemData.status === 'C'"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickPopReupload"
        >
          공고 재등록
        </button>
        <button
          v-if="itemData.status === 'T'"
          type="button"
          class="btn_primary btn_large"
          @click="onClickPost"
        >
          게시
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <!-- <PurchaseRequestJiraDetailPopup
        v-if="isPopDetailJira"
        :requestCid="requestCidSelected"
        @onClickClose='onClickCloseDetail'
        @alert="onAlert" /> -->
      <PurchaseRequestDetailPopup
        v-if="isPopDetail"
        :requestCid="requestCidSelected"
        @onClickClose="onClickCloseDetail"
        @alert="onAlert"
      />
      <AlertPopup
        v-if="isReuploadPopupMode"
        alertText="해당 공고를 재 등록 하시겠습니까?<br>* 첨부 파일은 재 등록되지 않습니다. 내용을 꼭 확인해주세요"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickReAdd"
      />
      <AlertPopup
        v-if="isDeletePopupMode"
        alertText="해당 공고를 삭제 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickClose"
        @onClickPrimary="onClickDelete"
      />
      <CloseAnnouncePopup
        v-if="isPopAnnounceClose"
        :loginIdWithName="myLoginIdWithName"
        @onClickClose="onClickClosePopAnnounceClose"
        @onClickSubmit="onClickCloseAnnounce"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
// import PurchaseJiraConnectView from '@/components/shared/purchaseJira/PurchaseJiraConnectView'
import FileView from "@/components/common/file/FileView";
import TableHead from "@/components/shared/TableHead";
import ConnectView from "@/components/common/connect/ConnectView";
import Board from "@/components/common/board/Board";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import PurchaseRequestDetailPopup from "@/components/admin/purchaseRequest/popup/PurchaseRequestDetailPopup";
// import PurchaseRequestJiraDetailPopup from '@/components/admin/purchaseRequest/popup/PurchaseRequestJiraDetailPopup'
import CloseAnnouncePopup from "@/components/admin/bid/popup/CloseAnnouncePopup";

import PageMixin from "@/mixins/PageMixin";
import { getDateString, getDateStringWithTime } from "@/utils/dateUtils";
import { addCommaForMoney, decodeHTMLEntities, getIsValidHtml } from "@/utils/stringUtils";
import {
  getCategoryDepthText,
  getEvaluateData,
  getCountWithCurrency,
  getCountWithQuantity,
} from "@/utils/spUtils";
import { getPurchaseDraftDetailPath } from "@/utils/DetailPathUtils";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "AnnounceView",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    // PurchaseJiraConnectView,
    TableHead,
    ConnectView,
    Board,
    FileView,
    Sticky,
    AlertPopup,
    PurchaseRequestDetailPopup,
    // PurchaseRequestJiraDetailPopup,
    CloseAnnouncePopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemData: {},
      itemId: "",

      // callPostList: [
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"시오 테스트 - 자산관리시스템 연동",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"시오 테스트 - 자산관리시스템 연동",
      //   },
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"구매(입찰)관리 시스템/총무 자산관리 시스템 외주 개발건",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"구매(입찰)관리 시스템/총무 자산관리 시스템 외주 개발건",
      //   },
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"구매(입찰)관리 시스템/총무 자산관리 시스템 외주 개발건",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"구매(입찰)관리 시스템/총무 자산관리 시스템 외주 개발건",
      //   },
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"구매(입찰)관리 시스템 외주 개발 건",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"구매(입찰)관리 시스템 외주 개발 건",
      //   },
      //   {
      //     category:"SW,개발,Adobe",
      //     goods:"구매(입찰)관리 시스템 외주 개발 건",
      //     requestCid:"DKYHNW6GURFR",
      //     requestDate:"20191210124258",
      //     requester:"sio.oh",
      //     signDate:"20191210124333",
      //     signer:"jenny.park",
      //     title:"구매(입찰)관리 시스템 외주 개발 건",
      //   }
      // ],
      // connectDataList: [],
      // connectDataList:[
      //   {'fileName':'구매(입찰)관리 시스템 외주 개발 건','askerName':'ayla.kim','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템, 총무자산관리 시스템 외주 개발 건','askerName':'jason.jeong888','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템, 구매(입찰)관리 시스템, 구매(입찰)관리 시스템, 구매(입찰)관리 시스템','askerName':'kerry.youbo','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템 외주 개발 건','askerName':'shankly.bill','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템','askerName':'justin.koo','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템, 구매(입찰)관리 시스템, 구매(입찰)관리 시스템, 구매(입찰)관리 시스템','askerName':'kerry.youbo','fileUrl':'./order/1'},
      //   {'fileName':'구매(입찰)관리 시스템, 총무자산관리 시스템 외주 개발 건','askerName':'jason.jeong888','fileUrl':'./order/1'},
      // ],
      // isPopDetailJira:false,

      isPopDetail: false,
      requestCidSelected: String,

      isReuploadPopupMode: false,
      isDeletePopupMode: false,

      isPopAnnounceClose: false,
    };
  },
  computed: {
    isShowPurchaseRequest() {
      return LocalStorageManager.shared.getIsShowPurchaseRequest();
    },
    quantity() {
      const { quantity, unitValue } = this.itemData;

      return getCountWithQuantity(quantity, unitValue);
    },
    budget() {
      const { budget, currency } = this.itemData;

      return getCountWithCurrency(budget, currency);
    },
    noteDecoded() {
      const note = this.itemData.note;

      if (!note) return "";

      const result = decodeHTMLEntities(document, note);

      if (!getIsValidHtml(result)) return null;

      return result;
    },
    startDate() {
      if (!this.itemData.startDate) return "";
      return getDateStringWithTime(this.itemData.startDate);
    },
    endDate() {
      if (!this.itemData.endDate) return "";
      return getDateStringWithTime(this.itemData.endDate);
    },
    // budget() {
    //   if( !this.itemData.budget ) return '';
    //   return addCommaForMoney( String( this.itemData.budget ) );
    // },
    // 구매 담당자
    managerPurchase() {
      const arrPur = this.itemData.purchasePersonList;
      if (!arrPur) return null;

      const itemP = arrPur.find((item) => item.code === "P");
      return itemP;
    },
    // 현업 담당자
    managerFieldWork() {
      const arrPur = this.itemData.purchasePersonList;
      if (!arrPur) return null;

      const itemP = arrPur.find((item) => item.code === "B");
      return itemP;
    },
    isChoiceTypeOnlyPrice() {
      const postEvaluate = this.itemData.postEvaluate;
      if (!postEvaluate) return true;

      return postEvaluate.choiceType === "P";
    },
    priceTypeText() {
      const { postEvaluate } = this.itemData;
      if (!postEvaluate) return "";

      const { priceType } = postEvaluate;
      if (!priceType) return "";

      const item = getEvaluateData(true, priceType);
      return item.desc;

      // switch( postEvaluate.choiceType ) {
      //   case 'O': return '공개';
      //   case 'C': return '입찰마감 후 공개';
      // }

      // return ''
    },
    // 물품,하드웨어,서버 -> 물품>하드웨어>서버
    category() {
      if (!this.itemData || !this.itemData.category) return "";
      return getCategoryDepthText(this.itemData.category);
    },
    myLoginIdWithName() {
      return LocalStorageManager.shared.getUserLoginIdWithName();
    },
  },
  beforeMount() {
    this.itemId = this.$route.params.id;
    const path = `${this.$apiPath.ANNOUNCE}/${this.itemId}`;

    this.$store.dispatch(PROGRESS_START_ACTION);

    this.getData(path);
  },
  methods: {
    async getData(path) {
      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);

      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text, () => {
          this.$router.push({
            path: this.$routerPath.ANNOUNCE_LIST,
          });
        });
        return;
      }
      if (!result.data) return;

      this.itemData = result.data;

      // const { requestPostList } = this.itemData;

      // this.connectDataList = requestPostList || [];
    },
    refresh() {
      const path = `${this.$apiPath.ANNOUNCE}/${this.itemId}`;

      this.getData(path);
    },
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    onClickClose() {
      this.isReuploadPopupMode = false;
      this.isDeletePopupMode = false;
    },
    onClickCloseDetail() {
      this.requestCidSelected = "";
      this.isPopDetail = false;
      // this.isPopDetailJira = false;
    },
    // 구매신청서 상세 열기
    onClickConnectRequestPost(item) {
      this.requestCidSelected = item.requestCid;
      this.isPopDetail = true;
    },
    // onClickDetailJira( id ){
    //   this.requestCidSelected = id;
    //   this.isPopDetailJira = true;
    // },
    onAlert(text) {
      this.alert(text);
    },
    onClickPopReupload() {
      this.isReuploadPopupMode = true;
    },
    onClickPopDelete() {
      this.isDeletePopupMode = true;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.ANNOUNCE_LIST,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.ANNOUNCE_MODIFY}/${this.itemId}`,
      });
    },
    async onClickReAdd() {
      const path = `${this.$apiPath.ANNOUNCE}/${this.itemId}${this.$apiPath.COPY}`;

      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        this.alert(result.text);
        return;
      }

      const newPostCid = result.data.postCid;

      this.$router.push({
        // path: `${ this.$routerPath.ANNOUNCE_WRITE }/${ newPostCid }`,
        name: this.$routerPath.ANNOUNCE_WRITE,
        params: {
          id: newPostCid,
          prevData: result.data,
        },
      });
    },
    onClickDelete() {
      this.deleteItem();
    },
    async deleteItem() {
      const path = `${this.$apiPath.ANNOUNCE}/${this.itemId}`;
      const result = await ApiService.shared.deleteData(path);

      this.isDeletePopupMode = false;

      this.$router.push({
        path: this.$routerPath.ANNOUNCE_LIST,
      });
    },
    onClickEnd() {
      this.isPopAnnounceClose = true;
    },
    onClickCloseAnnounce(text) {
      this.closeAnnounce(this.itemId, text);
    },
    async closeAnnounce(postCid, text) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.CLOSE}`;
      const userData = LocalStorageManager.shared.getUserData();

      const obj = {
        reason: text,
      };

      const result = await ApiService.shared.putData(path, obj);
      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      // this.sendMail();

      this.refresh();

      this.isPopAnnounceClose = false;
    },
    onClickClosePopAnnounceClose() {
      this.isPopAnnounceClose = false;
    },
    async onClickPost() {
      const path = `${this.$apiPath.ANNOUNCE}/${this.itemId}?temp=N`;

      // 받은 그대로 전달하니까 노트 <p>abc</abc> 가 &lt;p&gt;&lt;br&gt;&lt;/p&gt;" 이런 식으로 보내져서
      // 이중으로 변환 됨. 그래서 상세에서 바로 게시할 때는 다시 <p>abc</abc> 형태로 되돌려서 보낸다.
      const objSend = Object.assign({}, this.itemData);
      objSend.note = decodeHTMLEntities(document, this.itemData.note);

      const result = await ApiService.shared.putData(path, objSend);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      // 저장 성공 시 그냥 목록으로.
      // this.$router.push({
      //   path: this.$routerPath.ANNOUNCE_LIST
      // });
      this.refresh();
    },
    // async sendMail(){
    //   this.partnerJoinListText();
    //   const userData = LocalStorageManager.shared.getUserData();
    //   const companyName = userData.companyName;

    //   const obj = {
    //     to : this.emailList,
    //     subject : '카카오 전자입찰 시스템 ' +companyName+ '의 공고  알림',
    //     companyName : companyName
    //   }

    //   const path = `${ this.$apiPath.MAIL_NEWPOST }`
    //   var result = await ApiService.shared.postData( path, obj );
    // },
    // partnerJoinListText() {
    //   const arrPos = this.itemData.postPersonList;
    //   if( !arrPos ) return null;

    //   const itemP = arrPos.find( item =>{
    //     this.emailList.push(item.email)
    //   });

    //   const itemC = arrPos.find( item =>{
    //     this.emailList.push(item.ceoEmail)
    //   });

    //   return this.emailList;
    // }
  },
};
</script>
<style scoped>
.group_form > .txt_view_r {
  line-height: 1.5;
}
</style>
